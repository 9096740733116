/* Responsive media by default */
img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

/* Full screen background image */
body, html {
  height: 100%;
  background: #2B2D30 url("/assets/background.jpg") no-repeat fixed center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @supports (background-image: url('/assets/background.webp')) {
    background-image: url('/assets/background.webp');
  }
}

a, a:hover {
  color: #16A3DE;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.content {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 1em;
}

@media only screen and (min-width: 992px) { /* Bootstrap large breakpoint for responsive content media */
  .content img {
    max-width: 70%
  }
}

.gallery img {
  max-width: 100%;
  max-height: 200px; /* 100% does not respect the height of the enclosing div */
}

.bg-asylum-dark {
  background-color: #2E6B73;
}

.post-info {
  color: #6c757d;
  font-size: 0.9rem;
}

.no-link {
  text-decoration: none !important;
}

// flex size youtube embedding.
// source: https://stackoverflow.com/questions/35814653/automatic-height-when-embedding-a-youtube-video
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sidebar-text {
  color: lightgray;
  font-weight: bold;
}
